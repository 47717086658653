import localStorageHelper from 'o365.modules.StorageHelpers.ts';
import {reactive,watch} from 'vue';

let settings:DbManagerSettings;
let localStorageKey:string = 'o365_dbmanager_settings';
export function getSettings(){
    if(!settings){
        settings = new DbManagerSettings();
        settings = reactive(settings);
        
        watch(settings,()=>{
            settings.save();
        })
    }
    
    return settings;
}


export default class DbManagerSettings{
    systemColumnsToshow:Array<SysField> = [];
    intelisense:boolean = true;
    quickFix:boolean = true;
    autoComplete:boolean = true;
    autoFixOnOpen:boolean = true;
    addRowNumbers:boolean = true;
    hideGridMenu:boolean = true;
    hideFilterRow:boolean = false;

    showOpenEditors:boolean = true;
    openEditorOpen:boolean = true;

    autoCloseInactiveTab:boolean = false;
    saveActiveTabsInMemory:boolean = true;
    notepadMode:boolean = false;

    constructor(){
       
        this.systemColumnsToshow.push(new SysField('PrimKey',true));
        this.systemColumnsToshow.push(new SysField('Updated',true));
        this.systemColumnsToshow.push(new SysField('UpdatedBy_ID',true));
        this.systemColumnsToshow.push(new SysField('Created',true));
        this.systemColumnsToshow.push(new SysField('CreatedBy_ID',true));
        this.systemColumnsToshow.push(new SysField('CCTL',false));
        
        let savedSettings = localStorageHelper.getItem(localStorageKey,{global:true});
        if(savedSettings){
            try{
                savedSettings = JSON.parse(savedSettings);
                if(savedSettings)
                Object.keys(savedSettings).forEach((key:any)=>{
                    if(key == "systemColumnsToshow"){
                        savedSettings['systemColumnsToshow'].forEach((field:SysField)=>{
                            const vField = this.systemColumnsToshow.find(x=>x.name == field.name);
                            if(vField){
                                vField.show = field.show;
                            }
                        })
                    }else if(this.hasOwnProperty(key)){
                        this[key] = savedSettings[key];
                    }
                })
            }catch{
                console.warn("Failed to parse Db Manager settings from local storage");
            }
        }
 
    }

    save(){
        

      
        localStorageHelper.setItem(localStorageKey,JSON.stringify(this),{global:true});
    }




}

class SysField {
    name:String;
    show:Boolean;

    constructor(name:string,show:boolean){
        this.name = name;
        this.show = show;
    }
}